<template>
    <div>
        <el-divider></el-divider>
        <el-col :span="12">
            <el-button-group>
                <el-button type="primary" @click="add">{{ $t("lang.增加") }}</el-button>
                <el-button type="info" @click="edit">{{ $t("lang.编辑") }}</el-button>
                <el-button type="danger" @click="del">{{ $t("lang.删除") }}</el-button>
            </el-button-group>
            <vxe-table ref="myTable" :loading="tableLoading" :data="tableData" style="margin-top: 5px;" border :row-config="{ isCurrent: true, isHover: true }">
                <vxe-column field="biaoDingDian" :title="$t('lang.标定点') + '(mol/L)'" align="right"></vxe-column>
                <vxe-column field="dianShi" :title="$t('lang.电势') + '(mV)'" align="right"></vxe-column>
                <vxe-column field="createTime" :title="$t('lang.创建时间')"></vxe-column>
            </vxe-table>
            <vxe-pager align="left" @page-change="getList" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
            </vxe-pager></el-col>
        <el-col :span="11" :offset="1">

            <el-input v-model.number="dianShi">
                <template #prepend>{{ $t('lang.电势') + '(mV)' }}</template>
            </el-input>
            <el-row type="flex" justify="end" style="margin-top: 5px;">
                <el-button type="primary" @click="calcResult" v-loading.fullscreen.lock="fullscreenLoading">{{ $t('lang.计算') }}</el-button>
            </el-row>
            <el-row type="flex" justify="center" style="margin-top: 5px;">
                <el-result :title="$t('lang.计算结果')" :subTitle="nongDu"></el-result>
            </el-row>

        </el-col>

        <vxe-modal v-model="visibleEditModal" :title="$t('lang.编辑')" showFooter>
            <vxe-form :data="editModel" :loading="formLoading" title-width="100">
                <vxe-form-item field="meiTianJiangWen" :title="$t('lang.标定点') + '(mol/L)'">
                    <template #default="{ data }">
                        <vxe-input v-model="data.biaoDingDian"> </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="meiSiXiaoShiJiangWen" :title="$t('lang.电势') + '(mV)'">
                    <template #default="{ data }">
                        <vxe-input v-model="data.dianShi"> </vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <vxe-button status="primary" @click="save" icon="vxe-icon-save">{{ $t("lang.保存") }}</vxe-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fullscreenLoading: false,
            nongDu: " ",
            dianShi: 0,
            tableLoading: false,
            formLoading: false,
            editModel: {},
            tableData: [],
            searchObj: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            visibleEditModal: false,
        };
    }, methods: {
        add() {
            let that = this;
            that.editModel = {};
            that.visibleEditModal = true;
        },
        edit() {
            let that = this;
            let currentRow = that.$refs.myTable.getCurrentRecord();
            if (currentRow) {
                that.axios.post("LLZ801_NongDuBiaoDing/GetModel", { onlyInt: currentRow.id }).then((response) => {
                    that.editModel = response.data.data;
                });
                that.visibleEditModal = true;
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择项目") });
            }
        },
        del() {
            let that = this;
            let currentRow = that.$refs.myTable.getCurrentRecord();
            if (currentRow) {
                that.$confirm(that.$t("lang.确定要删除吗"))
                    .then(function () {
                        that.axios.post("LLZ801_NongDuBiaoDing/Del", { onlyInt: currentRow.id }).then(function () {
                            that.$message({ type: "success", message: that.$t("lang.删除成功") });
                            that.getList();
                        });
                    })
                    .catch(() => { });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择项目") });
            }
        },
        save() {
            let that = this;
            that.axios.post("LLZ801_NongDuBiaoDing/Save", that.editModel).then((response) => {
                if (response.data.code == 101) {
                    that.$vxe.modal.message({ content: that.$t("lang.保存成功"), status: "success" });
                    that.visibleEditModal = false;
                    that.getList();
                }
            }).catch(() => {
                that.$vxe.modal.message({ content: that.$t("lang.保存失败"), status: "error" });
            });

        },
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios.post("LLZ801_NongDuBiaoDing/GetList", that.searchObj).then((response) => {
                that.tableData = response.data.data.data;
                that.searchObj.total = response.data.data.total;
                that.tableLoading = false;
            });
        },
        calcResult() {
            let that = this;
            that.fullscreenLoading = true;
            that.axios.post("LLZ801_NongDuBiaoDing/calcResult", {
                OnlyDouble: that.dianShi
            }).then((response) => {
                that.nongDu = response.data.data.toString() + 'mol/L';
                that.fullscreenLoading = false;
            }).catch(() => {
                that.fullscreenLoading = false;
            });
        }
    },

    mounted() {
        let that = this;
        that.getList();
    }

};
</script>

<style></style>